/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable arrow-body-style */
/* eslint-disable no-script-url */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import './viewelement.css';
import { GoogleMap, LoadScript, MarkerF } from '@react-google-maps/api';
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
// import axiosInstance from '.utils/axios';
import { useSnackbar } from 'notistack';
import axiosInstance from '../../../utils/axios';
// import { RHFDescription } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';

const ViewFavorite = () => {
  // const [value, onChange] = useState(new Date());

  const navigate = useNavigate();
  const location = useLocation();
  const [listing, setlisting] = useState();
  const [expirence, setexpirence] = useState();
  const datas = location?.state;
  console.log(datas, 'data');
  const [list, setlist] = useState(true);
  console.log(list, 'ttt');

  async function AllListings() {
    const data = await axiosInstance.get('listing-favourite');
    setlisting(data?.data?.data);
  }

  console.log(listing, 'listing');
  // const [lat,setlat]=useState(0)
  // const [lng,setlang]=useState(0)
  // const navigate = useNavigate();
  //   const users = location?.state?.user;

  // const arriveDateParts = datas?.arrive_date.split('-');
  // const departDateParts = datas?.depart_date.split('-');
  // const arriveDate = new Date(`20${arriveDateParts[0]}`, arriveDateParts[1] - 1, arriveDateParts[2]);
  // const departDate = new Date(`20${departDateParts[0]}`, departDateParts[1] - 1, departDateParts[2]);
  // const diffTime = Math.abs(departDate - arriveDate);
  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  // const nightlyRate = Number(datas?.listing?.nightly);
  // const totalCost = diffDays * nightlyRate;
  // console.log(totalCost, 'cost');
  const { enqueueSnackbar } = useSnackbar();

  // async function ConfirmBook(id) {
  //   console.log(id);
  //   try {
  //     const formdata = new FormData();
  //     formdata.append('booking_id', id);
  //     formdata.append('status', 'Waiting Payment');
  //     const response = await axiosInstance.post('booking-status-change', formdata);
  //     console.log(response, 'statuschange');
  //     if (response?.data?.status === true) {
  //       enqueueSnackbar(response?.data?.message);
  //       // AllListings();
  //       navigate('/dashboard/booking');
  //     }
  //   } catch (error) {
  //     enqueueSnackbar(error?.message, {
  //       variant: 'error',
  //     });
  //     console.error(error);
  //   }
  // }

  // async function Decline(id) {
  //   console.log(id);
  //   try {
  //     const formdata = new FormData();
  //     formdata.append('booking_id', id);
  //     formdata.append('status', 'Decline');
  //     const response = await axiosInstance.post('booking-status-change', formdata);
  //     console.log(response, 'statuschange');
  //     if (response?.data?.status === true) {
  //       enqueueSnackbar(response?.data?.message);
  //       navigate('/dashboard/booking');

  //       // AllListings();
  //     }
  //   } catch (error) {
  //     enqueueSnackbar(error?.message, {
  //       variant: 'error',
  //     });
  //     console.error(error);
  //   }
  // }

  async function Listing(id) {
    console.log(id);
    try {
      await axiosInstance.get(`user/${datas?.id}`).then((response) => {
        setlisting(response?.data);
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }

  async function ExperiencesListing() {
    const data = await axiosInstance.get('booking', {
      params: { user_id: datas?.id },
    });
    setexpirence(data);
    // setshortrental(data?.data?.data);
  }

  console.log('lis', listing?.user?.listings);
  console.log('exp', expirence?.data?.data);

  const mapRef1 = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef1.current = map;
  }, []);

  useEffect(() => {
    AllListings();
    // Listing();
    // ExperiencesListing();
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ pd: 4 }} md={6}>
          <img src={datas?.listing_images[0].image} alt="sd" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container sx={{ display: 'flex', alignItems: 'center', mt: 1.5 }} spacing={1}>
            <Grid item xs={12} md={5}>
              <img
                src={
                  datas?.listing_images && datas.listing_images[1]?.image
                    ? datas.listing_images[1].image
                    : 'https://img.freepik.com/free-photo/house-with-garage-garage-door_1340-32120.jpg?t=st=1720435358~exp=1720438958~hmac=1bf5bfe84b650268b5503a83a75a6cbfe7098134b27c2b0841bf5f30319b0743&w=740'
                }
                alt="sd"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <img
                src={
                  datas?.listing_images && datas.listing_images[2]?.image
                    ? datas.listing_images[2].image
                    : 'https://img.freepik.com/free-photo/house-with-garage-garage-door_1340-32120.jpg?t=st=1720435358~exp=1720438958~hmac=1bf5bfe84b650268b5503a83a75a6cbfe7098134b27c2b0841bf5f30319b0743&w=740'
                }
                alt="sd"
              />
            </Grid>
            <Grid item sx={{ mt: 2 }} xs={12} md={5}>
              <img
                src={
                  datas?.listing_images && datas.listing_images[3]?.image
                    ? datas.listing_images[3].image
                    : 'https://img.freepik.com/free-photo/house-with-garage-garage-door_1340-32120.jpg?t=st=1720435358~exp=1720438958~hmac=1bf5bfe84b650268b5503a83a75a6cbfe7098134b27c2b0841bf5f30319b0743&w=740'
                }
                alt="sd"
              />
            </Grid>
            <Grid item sx={{ mt: 2 }} xs={12} md={5}>
              <img
                src={
                  datas?.listing_images && datas.listing_images[4]?.image
                    ? datas.listing_images[4].image
                    : 'https://img.freepik.com/free-photo/house-with-garage-garage-door_1340-32120.jpg?t=st=1720435358~exp=1720438958~hmac=1bf5bfe84b650268b5503a83a75a6cbfe7098134b27c2b0841bf5f30319b0743&w=740'
                }
                alt="sd"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 5 }} spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, alignItems: 'start' }}>
            <Box
              sx={{
                mt: 1,
                ml: 3,
                display: 'grid',
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <Typography variant="div">
                <Typography variant="h5" gutterBottom>
                  {datas?.listing_type} Hosted by {datas?.host?.username}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {datas?.no_of_guest} Guest . {datas?.no_of_bed} Bed with {datas?.no_of_bedroom} bedrooms
                </Typography>
              </Typography>
            </Box>

            {/* <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
              }}
            >
              <Typography variant="h6" sx={{mt:2,ml:5}} >
                Listing Name:
              </Typography>
            </Box> */}
            <Divider sx={{ mt: 2 }}>-</Divider>

            <Box
              sx={{
                mt: 2,
                display: 'grid',
                columnGap: 2,
                ml: 4,
                mr: 4,
                rowGap: 3,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">About this Listing </Typography>
              </Typography>

              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">{datas?.description}</Typography>
              </Typography>
            </Box>
            <Divider sx={{ mt: 4 }}>-</Divider>
            <Typography variant="h5" sx={{ mt: 1, ml: 2 }}>
              Detail
            </Typography>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                mt: 4,
                pl: 4,
                pr: 4,

                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">ID # : {datas?.id}</Typography>
                <Typography variant="body2">Check In After : {datas?.check_in_after}</Typography>
              </Typography>
              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Guests : {datas?.no_of_guest} </Typography>
                <Typography variant="body2"> Check Out Before : {datas?.check_in_before}</Typography>
              </Typography>

              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Bedrooms : {datas?.no_of_bedroom}</Typography>
                <Typography variant="body2">Dewlling : {datas?.listing_type}</Typography>
              </Typography>

              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Bed : {datas?.no_of_bed}</Typography>
                <Typography variant="body2">Size : {datas?.size}</Typography>{' '}
              </Typography>

              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Bathrooms : {datas?.no_of_bathroom}</Typography>
                <Typography variant="body2">Additional information : {datas?.addi_info}</Typography>{' '}
              </Typography>
            </Box>
            <Divider sx={{ mt: 2 }}>-</Divider>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Gallery
            </Typography>
            <Grid container spacing={1} sx={{ mt: 2 }}>
              {datas?.listing_images.length > 0
                ? datas?.listing_images.map((e) => {
                    return (
                      <>
                        <Grid item xs={12} sx={{ pd: 4 }} md={3}>
                          <img src={e?.image} alt="sd" />
                        </Grid>
                      </>
                    );
                  })
                : ''}
              {/* <Grid item xs={12} sx={{ pd: 4 }} md={3}>
                <img
                  src={
                    'https://img.freepik.com/free-photo/house-with-garage-garage-door_1340-32120.jpg?t=st=1720435358~exp=1720438958~hmac=1bf5bfe84b650268b5503a83a75a6cbfe7098134b27c2b0841bf5f30319b0743&w=740'
                  }
                  alt="sd"
                />
              </Grid>
              <Grid item xs={12} sx={{ pd: 4 }} md={3}>
                <img
                  src={
                    'https://img.freepik.com/free-photo/house-with-garage-garage-door_1340-32120.jpg?t=st=1720435358~exp=1720438958~hmac=1bf5bfe84b650268b5503a83a75a6cbfe7098134b27c2b0841bf5f30319b0743&w=740'
                  }
                  alt="sd"
                />
              </Grid>
              <Grid item xs={12} sx={{ pd: 4 }} md={3}>
                <img
                  src={
                    'https://img.freepik.com/free-photo/house-with-garage-garage-door_1340-32120.jpg?t=st=1720435358~exp=1720438958~hmac=1bf5bfe84b650268b5503a83a75a6cbfe7098134b27c2b0841bf5f30319b0743&w=740'
                  }
                  alt="sd"
                />
              </Grid> */}
            </Grid>

            <Divider sx={{ mt: 4 }}>-</Divider>
            <Typography variant="h5" sx={{ mt: 1, ml: 2 }}>
              Prices
            </Typography>
            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                mt: 4,
                pl: 4,
                pr: 4,

                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Night : $ {datas?.nightly}</Typography>
                <Typography variant="body2">Allowed Additional Guests : {datas?.addi_guest}</Typography>
              </Typography>
              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Weekend : ({datas?.apply_weekend_price}) </Typography>
                <Typography variant="body2">Cleaning Fee : $ {datas?.clean_fee}</Typography>
              </Typography>

              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Weekly : ({datas?.weekly_seven_plus} +) </Typography>
                <Typography variant="body2">City Fee : $ {datas?.city_fee_per_stay} Per Stay</Typography>
              </Typography>

              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Monthly : ( {datas?.weekly_thirty_plus} + ) </Typography>
                <Typography variant="body2">Minimum day of Booking : {datas?.min_day_booking}</Typography>{' '}
              </Typography>

              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Security Deposit : $ {datas?.security_deposit}</Typography>
                <Typography variant="body2">Maximum day of Booking : {datas?.max_day_booking}</Typography>{' '}
              </Typography>

              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Additional Guests : {datas?.addi_guest}</Typography>
              </Typography>
            </Box>

            <Divider sx={{ mt: 4 }}>-</Divider>
            <Typography variant="h5" sx={{ mt: 1, ml: 2 }}>
              Extra Service
            </Typography>

            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                mt: 4,
                pl: 4,
                pr: 4,

                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              {/* <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {datas?.listing_extra_service_prices.map((e) => {
                  return (
                    <>
                      <Typography variant="body2">{e?.name}</Typography>
                    </>
                  );
                })}
              </Typography> */}
            </Box>

            <Divider sx={{ mt: 4 }}>-</Divider>
            <Typography variant="h5" sx={{ mt: 1, ml: 2 }}>
              Accomodation
            </Typography>

            <Box
              sx={{
                display: 'grid',
                rowGap: 1,
                mt: 3,
                pl: 4,
                pr: 4,

                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">Badrooms</Typography>
              </Typography>
              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">{datas?.size} queen size</Typography>
              </Typography>
              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">{datas?.no_of_guest} Guest</Typography>
              </Typography>
            </Box>

            <Divider sx={{ mt: 4 }}>-</Divider>
            <Typography variant="h5" sx={{ mt: 1, ml: 2 }}>
              Feature
            </Typography>

            {/* <Box
              sx={{
                display: 'grid',
                rowGap: 1,
                mt: 3,
                pl: 4,
                pr: 4,

                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              {/* <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6">Amenities</Typography>
              </Typography> */}
            {/* {JSON.parse(datas?.amenities)?.length > 0 
                ? JSON.parse(datas?.amenities)?.map((e) => {
                    return (
                      <>
                        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="body2">{e}</Typography>
                        </Typography>
                      </>
                    );
                  })
                : ''}
              <Typography sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                <Typography variant="h6">Facilities</Typography>
              </Typography>
              {JSON.parse(datas?.facilities)?.length > 0
                ? JSON.parse(datas?.facilities)?.map((e) => {
                    return (
                      <>
                        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="body2">{e}</Typography>
                        </Typography>
                      </>
                    );
                  })
                : ''}

              <Divider sx={{ mt: 4 }}>-</Divider>
              <Typography variant="h5" sx={{ mt: 1, ml: 2 }}>
                Map
              </Typography>
              <LoadScript googleMapsApiKey={'AIzaSyDXJS_VZMhnp0szh92aZGg8RHszz6RMQN8'}>
                <GoogleMap
                  mapContainerStyle={{ height: '500px' }}
                  center={{
                    lat: Number(location?.state?.lat),
                    lng: Number(location?.state?.lng),
                  }}
                  zoom={4}
                  onLoad={onMapLoad}
                >
                  <MarkerF
                    position={{
                      lat: Number(location?.state?.lat),
                      lng: Number(location?.state?.lng),
                    }}
                  />
                </GoogleMap>
              </LoadScript>
            </Box> */}

            <Divider sx={{ mt: 4 }}>-</Divider>
            <Typography variant="h5" sx={{ mt: 1, ml: 2 }}>
              Video
            </Typography>

            <iframe
              style={{ marginTop: '20px' }}
              title="Things to Check Before Moving Into an Apartment : Real Estate Tips"
              width="100%"
              height="350"
              data-src="https://www.youtube.com/embed/cuGfG0J1aIw?feature=oembed"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              src="https://www.youtube.com/embed/cuGfG0J1aIw?feature=oembed"
              className=" lazyloaded"
              data-load-mode="1"
            />

            <Divider sx={{ mt: 4 }}>-</Divider>
            <Typography variant="h5" sx={{ mt: 1, ml: 2 }}>
              Terms & Rules
            </Typography>

            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                mt: 4,
                pl: 4,
                pr: 4,

                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Smoking Allowed : </Typography>
                <Typography variant="body2"> {datas?.smoking_allowed == 1 ? 'Yes' : 'No'}</Typography>
              </Typography>

              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Pets Allowed : </Typography>
                <Typography variant="body2"> {datas?.pet_allowed == 1 ? 'Yes' : 'No'}</Typography>
              </Typography>

              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Partys Allowed : </Typography>
                <Typography variant="body2"> {datas?.party_allowed == 1 ? 'Yes' : 'No'}</Typography>
              </Typography>

              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Children Allowed : </Typography>
                <Typography variant="body2">{datas?.children_allowed == 1 ? 'Yes' : 'No'}</Typography>
              </Typography>

              <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">House Rule & Additional Information : </Typography>
                <Typography variant="body2">{datas?.house_rule_addi_info}</Typography>
              </Typography>
            </Box>

            <Divider sx={{ mt: 4 }}>-</Divider>

            <Box
              sx={{
                display: 'grid',
                // columnGap: 2,
                // rowGap: 3,
                mt: 1,
                pl: 4,
                pr: 4,

                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
              }}
            >
              <Typography variant="h5" sx={{ mt: 1 }}>
                Availability
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                The minimum stay in {datas?.min_day_booking} Nights
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                The maximum stay in {datas?.max_day_booking} Nights
              </Typography>
              {/* 
            <Typography variant="h5" sx={{ mt: 1, ml: 2 }}>
             Availability
            </Typography> */}
            </Box>

            {/* <Calendar onChange={onChange} value={value} /> */}

            <Typography variant="h6" sx={{ mt: 3 }}>
              {' '}
              Hosted by {datas?.host?.username}
            </Typography>
            <Typography variant="h6" sx={{ mt: 3 }}>
              Language
            </Typography>
            <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
              {datas?.host?.other_language}
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 2 }}>
              <Typography variant="h6" sx={{ mt: 3 }}>
                Profile Status
              </Typography>
              {datas?.host?.status == 1 ? (
                <Button
                  variant="contained"
                  color={'success'}
                  sx={{ mt: 3, fontSize: 12 }}
                  onClick={() => setlist(true)}
                >
                  Verified
                </Button>
              ) : (
                <Button variant="contained" color={'error'} sx={{ mt: 3, fontSize: 12 }} onClick={() => setlist(true)}>
                  Not Verified
                </Button>
              )}
            </Box>
            {/* <Button
               
              variant="contained"
              color="success"
              sx={{  fontSize: 14,width:'20%',mt:5 }}
              onClick={() => {
                navigate(`/dashboard/viewuser/${datas?.host?.id}`, { state: datas });
              }}
            >
              View Profile
            </Button> */}
          </Card>

          <>
            <Card sx={{ p: 3, alignItems: 'start', mt: 3 }}>
              <Typography>Similar Listing</Typography>

              {listing?.length > 0 ? (
                listing?.slice(0, 5).map((data, index) => (
                  <Card key={index} sx={{ p: 3, alignItems: 'start', mt: 3 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                        <img src={data?.listing_images[0]?.image} alt="sd" />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Typography variant="h6">{data?.title}</Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                          {data?.description}
                        </Typography>
                        <Typography sx={{ display: 'flex', gap: 2, mt: 1.5, ml: -1 }}>
                          <Typography
                            sx={{ fontSize: '13px', display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'nowrap' }}
                          >
                            <Iconify icon={'material-symbols:bedroom-child'} color="black" width={32} height={25} />
                            {data?.no_of_bedroom} BedRoom
                          </Typography>
                          <Typography
                            sx={{ fontSize: '13px', display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'nowrap' }}
                          >
                            <Iconify icon={'solar:bath-bold'} color="black" width={32} height={25} />
                            {data?.no_of_bathroom} BathRoom
                          </Typography>
                          <Typography
                            sx={{ fontSize: '13px', display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'nowrap' }}
                          >
                            <Iconify icon={'fluent:guest-12-filled'} color="black" width={32} height={25} />
                            {data?.no_of_guest} Guests
                          </Typography>
                        </Typography>
                        <Typography sx={{ mt: 1.5, fontSize: '13px' }}>{data?.listing_type}</Typography>
                        <Typography
                          sx={{ display: 'flex', mt: 1, gap: 1, alignItems: 'center', ml: -0.5, fontSize: '13px' }}
                        >
                          <Iconify icon={'mdi:user'} color="black" width={32} height={25} />
                          Hosted By : {data?.host?.first_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                ))
              ) : (
                <Typography>No listings available</Typography>
              )}
            </Card>
          </>
        </Grid>
        <Grid item xs={12} sx={{ position: 'sticky' }} md={4}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ mt: 1, ml: 2 }}>
              $ {datas?.nightly} /Night
            </Typography>
            <Divider sx={{ mt: 2 }}>-</Divider>

            <Box
              sx={{
                display: 'grid',
                columnGap: 2,
                rowGap: 3,
                mt: 4,
                pl: 1,
                pr: 1,

                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
              }}
            >
              <Typography variant="body2" sx={{ mb: -2 }}>
                Arrive Date
              </Typography>
              <TextField type="date" />
              <Typography variant="body2" sx={{ mb: -2 }}>
                Depart Date
              </Typography>

              <TextField type="date" />

              <TextField type="number" label="Guest" />

              <Typography variant="h6" sx={{ mb: -2 }}>
                Extra services
              </Typography>
              <Box sx={{ display: 'flex', gap: 8 }}>
                {datas?.listing_extra_service_prices?.map((e) => {
                  return (
                    <>
                      <FormGroup sx={{ mt: 2 }}>
                        <FormControlLabel control={<Checkbox />} label={e?.name} />
                      </FormGroup>

                      <Box sx={{ mt: 1 }}>
                        <Typography variant="body2" sx={{ mt: 2.5 }}>
                          $ {e?.price}
                        </Typography>
                      </Box>
                    </>
                  );
                })}
              </Box>
              <TextField multiline={Boolean(true)} label="Introduce yourself to the host" maxRows={5} />
              <Button
                fullWidth
                variant="contained"
                color="success"
                sx={{ pt: 1, pb: 1, fontSize: 14 }}
                // onClick={() => ConfirmBook(datas?.id, datas?.status)}
              >
                Request to Book
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewFavorite;
